<template>
  <div class="revamp-container">
    <div class="revamp-wrapper-carousel">
      <b-carousel
        id="revamp-carousel-promotions"
        v-model="slide"
        :interval="timeInterval"
        controls
        indicators
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <b-carousel-slide
          v-for="item in promotions"
          :id="item.dataTest"
          :key="item.dataTest"
        >
          <div
            class="wrap-banner"
            :data-test="item.dataTest"
            @click="bannerClick(item)"
          >
            <Picture
              :image-file-name="item.detailImage.mobile"
              :tablet-image-file-name="item.carouselImage.desktop"
              :desktop-image-file-name="item.carouselImage.desktop"
              :alt="item.carouselImage.alt"
              class="promotion-img"
              :img-id="item.dataTest"
              width="100%"
              heigth="100%"
              :data-test="item.dataTest"
            />
          </div>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <div class="revamp-banner-promote">
      <div class="wrap-image">
        <img
          src="~/assets/images/revamp/girl-revamp-banner.svg"
          alt="จบทุกปัญหาทางการเงินคาร์ ฟอร์ แคช ช่วยได้ สินเชื่อรถ รีไฟแนนซ์รถ อนุมัติไว ดอกเบี้ยต่ำ"
        />
      </div>
      <div class="control-flex">
        <div class="text-promote">
          <div>
            <h1 class="first-text">จบทุกปัญหาทางการเงิน</h1>
            <h1 class="special-text">คาร์ ฟอร์ แคช ช่วยได้</h1>
          </div>
          <h1 class="last-text">
            สินเชื่อรถ รีไฟแนนซ์รถ <br class="mobile" />อนุมัติไว ดอกเบี้ยต่ำ
          </h1>
        </div>
        <div class="wrapper-btn">
          <Button id="btn-calculate" class="calculate" @click="handleClickCal()"
            >ประเมินวงเงิน</Button
          >
          <Button id="btn-loan-request" class="loan" @click="handleClickLoan()"
            >ขอสินเชื่อ</Button
          >
        </div>
      </div>
    </div>
    <div class="revamp-key-value section-detail">
      <h2>ทำไมต้อง Car4Cash</h2>
      <div class="grid-column">
        <div class="item-column">
          <div class="image">
            <img
              src="~/assets/images/revamp/img-column1.svg"
              alt="ให้วงเงินสูง"
            />
          </div>
          <div>
            <h3>ให้วงเงินสูง</h3>
            <p>
              วงเงินประเมินตามราคากลางรถ<br />เพื่อได้รับอนุมัติวงเงินกู้สูงสุดเต็ม
              100%<br />ของราคารถ
            </p>
          </div>
        </div>
        <div class="item-column">
          <div class="image">
            <img src="~/assets/images/revamp/img-column2.svg" at="อนุมัติไว" />
          </div>
          <div>
            <h3>อนุมัติไว</h3>
            <p>
              อนุมัติง่ายเมื่อเอกสารครบ<br />อายุรถตรงตามเกณฑ์<br />ไม่มีสลิปเงินเดือนก็ขอกู้ได้
            </p>
          </div>
        </div>
        <div class="item-column">
          <div class="image">
            <img
              src="~/assets/images/revamp/img-column3.svg"
              alt="ดอกเบี้ยต่ำ"
            />
          </div>
          <div>
            <h3>ดอกเบี้ยต่ำ</h3>
            <p v-if="$features('DWB-1391-UPDATE-FAQ-F')">
              ดอกเบี้ยสินเชื่อรถยนต์เริ่มต้น 0.27%,
              <br />รีไฟแนนซ์บิ๊กไบค์เริ่ม 0.46%, <br />สินเชื่อมอเตอร์ไซค์เริ่ม
              1.07% ต่อเดือน
            </p>
            <p v-else>
              ดอกเบี้ยสินเชื่อรถยนต์เริ่มต้น 0.27%,
              <br />รีไฟแนนซ์บิ๊กไบค์เริ่ม 0.38%, <br />สินเชื่อมอเตอร์ไซค์เริ่ม
              1.07% ต่อเดือน
            </p>
          </div>
        </div>
        <div class="item-column">
          <div class="image">
            <img src="~/assets/images/revamp/img-column4.svg" alt="ผ่อนสบาย" />
          </div>
          <div>
            <h3>ผ่อนสบาย</h3>
            <p>
              รถยนต์ผ่อนได้ถึง 84 เดือน, <br />บิ๊กไบค์ผ่อนนาน 48 เดือน,
              <br />มอเตอร์ไซค์ผ่อนสบายถึง 36 เดือน
            </p>
          </div>
        </div>
      </div>
    </div>
    <Contents
      id="revamp-blogs"
      data-test="contents-section"
      homepage
      events="homepage"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import jsQR from 'jsqr'
import { filter, get, isEmpty } from 'lodash'
import { isWithinInterval, isAfter } from 'date-fns'
import Contents from '../../pages/contents/footer-contents.vue'
import Promotions from '~/pages/promotions/configs-promotions'
import Picture from '~/components/commons/bases/picture/picture.vue'
import Button from '~/components/commons/bases/button/button.vue'
export default Vue.extend({
  name: 'HomeRevampVersion',
  components: {
    Contents,
    Picture,
    Button
  },
  data() {
    const promotions = filter(Promotions, promotion => this.onTime(promotion))
    return {
      promotions,
      slide: 0,
      timeInterval: 5000,
      sliding: null,
      refCode: null,
      vehicle: ['BB']
    }
  },
  methods: {
    async startScan() {
      this.refCode = null
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment' }
        })
        this.$refs.video.srcObject = stream
        this.$refs.video.style.display = 'block'
        this.scanQRCode()
      } catch (error) {
        console.error('Error accessing the camera', error)
      }
    },
    scanQRCode() {
      const canvasElement = document.createElement('canvas')
      const canvas = canvasElement.getContext('2d')
      const video = this.$refs.video
      canvasElement.style.display = 'block'

      const scan = () => {
        if (video.readyState === video.HAVE_ENOUGH_DATA) {
          canvasElement.height = video.videoHeight
          canvasElement.width = video.videoWidth
          canvas.drawImage(
            video,
            0,
            0,
            canvasElement.width,
            canvasElement.height
          )
          const imageData = canvas.getImageData(
            0,
            0,
            canvasElement.width,
            canvasElement.height
          )
          const code = jsQR(imageData.data, imageData.width, imageData.height, {
            inversionAttempts: 'dontInvert'
          })

          if (code) {
            this.refCode = code.data
            // Stop the stream and video
            video.srcObject.getTracks().forEach(track => track.stop())
            video.style.display = 'none'
            canvasElement.style.display = 'none'
          } else {
            requestAnimationFrame(scan)
          }
        } else {
          requestAnimationFrame(scan)
        }
      }
      scan()
    },
    onTime(promotion) {
      let isOnTime = true
      let publishUp = promotion.publishUp
      const publishUpProd = get(promotion, 'publishUpProd', null)

      if (this.$env.ENVIRONMENT_NAME === 'prod' && publishUpProd) {
        publishUp = publishUpProd
      }
      const start = `${publishUp} 00:00:00 GMT+07:00`.replace(/-/g, '/')

      if (isEmpty(promotion.publishDown)) {
        isOnTime = isAfter(new Date(), new Date(start))
      } else {
        const down = `${promotion.publishDown} 23:59:59 GMT+07:00`.replace(
          /-/g,
          '/'
        )
        isOnTime = isWithinInterval(
          new Date(), // Now
          {
            start: new Date(start),
            end: new Date(down)
          }
        )
      }
      const haveFeatureToggle =
        typeof promotion.featureToggle === 'string'
          ? this.$features(promotion.featureToggle)
          : promotion.featureToggle

      return haveFeatureToggle && isOnTime
    },
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    handleClickCal() {
      this.$analytics.vehicleSelectPage.landforms({
        source: 'home page',
        action: 'click estimate button on home page'
      })
      this.$gtag.event('click', {
        event_action: 'select_vehicle',
        event_label: 'homepage'
      })
      this.$router.push({
        path: '/vehicle-select'
      })
    },
    handleClickLoan() {
      this.$gtag.event('click', {
        event_action: 'click_loan_request',
        event_label: 'homepage'
      })
      this.$router.push({
        path: '/lead-form',
        query: {
          ...this.$route.query,
          clickLeadForm: 'y',
          events: 1,
          click: 'form',
          subCampaignName: 'EFORM'
        }
      })
    },
    bannerClick(item) {
      this.$gtag.event('click', {
        event_action: 'click_banner_from_carousel',
        event_label: item.utmCampaign
      })
      this.$router.push({
        path: item.url,
        query: {
          events: '2'
        }
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.control-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 65px;
  height: 100%;
  @media (max-width: 1119px) {
    gap: 30px;
  }
  @media (max-width: 991px) {
    gap: 24px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 5px;
  }
}
.text-promote {
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
    align-items: center;
    @media (max-width: 991px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  span {
    display: inline-block;
  }
  .first-text {
    font-size: 24px;
    font-weight: 500;
    color: #212121;
    margin-right: 16px;
    @media (max-width: 1119px) {
      font-size: 18px;
    }
    @media (max-width: 991px) {
      font-size: 16px;
    }
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
  .special-text {
    color: #ec222a;
    font-size: 26px;
    font-weight: 700;
    @media (max-width: 1119px) {
      font-size: 20px;
    }
    @media (max-width: 991px) {
      font-size: 28px;
      line-height: 36.4px;
      margin-bottom: 4px;
    }
    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }
  .last-text {
    font-size: 18px;
    font-weight: 500;
    color: #757575;
    display: block;
    @media (max-width: 991px) {
      font-size: 12px;
    }
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
}
.revamp-wrapper-carousel {
  margin-top: 45px;
  @media (max-width: 991px) {
    margin-top: 0;
  }
  @media (max-width: 767px) {
    margin-top: 16px;
  }
}
#revamp-carousel-promotions .carousel-inner {
  max-width: 1200px;
  width: 100%;

  @include mobile {
    max-width: 768px;
    width: 100%;
  }
}
.mobile {
  display: none;
  @include mobile {
    display: inline;
  }
}
.revamp-banner-promote {
  display: grid;
  grid-template-columns: 142px auto;
  width: 100%;
  background-image: url('~assets/images/revamp/bg-revamp-promote.svg');
  background-repeat: no-repeat;
  border-radius: 16px;
  background-size: cover;
  margin-top: 60px;
  align-items: flex-end;
  min-height: 111px;
  height: 100%;
  padding: 0px 32px;
  @media (max-width: 991px) {
    padding: 16px;
  }
  @media (max-width: 767px) {
    justify-content: space-between;
    padding: 16px;
    padding-left: 0;
  }
  .wrap-image {
    position: relative;
    img {
      position: absolute;
      left: 0;
      bottom: 0px;
      height: 150px;
      @media (max-width: 991px) {
        bottom: -16px;
      }
      @media (max-width: 767px) {
        height: 209px;
        left: -15px;
      }
      @media (max-width: 376px) {
        height: 195px;
      }
    }
  }

  .wrapper-btn {
    display: flex;
    gap: 16px;
    @media (max-width: 991px) {
      flex-direction: column;
      gap: 8px;
    }
    button {
      min-width: 179px;
      height: 55px;
      border-radius: 8px;
      padding: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 991px) {
        min-width: inherit;
        width: 240px;
        max-width: 240px;
        height: 46px;
      }
      @media (max-width: 767px) {
        max-width: 179px;
        width: 179px;
        min-width: inherit;
        height: 40px;
      }
    }
    .calculate {
      border: 2px solid #ffd400;
      background: #ffffff;
    }
  }
}

.revamp-key-value,
.section-detail {
  display: flex;
  flex-direction: column;
  margin: 32px 0;
  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 16px;
    color: #1a1a1a;
  }
  .grid-column {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 40px;
    }
  }
  .item-column {
    text-align: center;
    max-width: 240px;
    margin: 0 auto;
    @media (max-width: 767px) {
      display: flex;
      text-align: left;
      max-width: 310px;
    }
    .image {
      @media (max-width: 767px) {
        margin-right: 16px;
      }
      img {
        object-fit: cover;
        height: 106px;
      }
    }
    h3 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 15px;
      @media (max-width: 991px) {
        margin-bottom: 8px;
      }
      @media (max-width: 767px) {
        font-size: 20px;
      }
    }
    p {
      color: #616161;
      font-size: 12px;
      font-weight: 500;
      @media (max-width: 991px) {
        font-size: 10px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
      }
      br {
        @media (max-width: 767px) {
          display: none;
        }
      }
    }
  }
}
.wrap-banner {
  cursor: pointer;
}
</style>
